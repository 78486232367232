import React, { useEffect, useMemo } from 'react';
import { Route, Switch, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import { useEffectOnce } from 'usehooks-ts';
import { isEmpty } from 'lodash';

import Spinner from 'common/Spinner';

import { State } from 'types/State';
import { RouteComponent } from 'types/Routes';
import { AlwaysOnFeatures, Features } from 'types/Features';

import { sidenavEnabled, topHeaderEnabled } from 'utils/webViewUtil';
import { featureEnabled } from 'utils/features';
import history from 'utils/history';
import { routeFilterer } from 'utils/routeUtil';

import LoginWrapper from 'Login/components/LoginWrapper/LoginWrapper';
import Sidenav from 'sharedComponents/Sidenav';
import TopHeader from 'sharedComponents/TopHeader/TopHeader';
import NetworkErrorNotifications from 'nonRouteFeatures/NetworkResponseHandler';
import AppProvider, { SIDE_NAV_STATE } from 'providers/AppProvider';
import ModalProvider from 'providers/ModalProvider';
import { selectAuthRequiredDomain } from 'store/auth/selectors';
import { checkLoginStatus } from 'store/auth/slice';
import useSentry from 'App/useSentry';
import ErrorBoundary from 'sharedComponents/ErrorBoundary';
import { AppDispatch } from 'store';
import { getPrivileges } from 'store/privileges/slice';
import SessionTimeout from 'App/SessionTimeout';
import createRoutes from 'routes';
import SplashScreen from 'shared/SplashScreen';
import messages from 'App/messages';
import { fetchPreferences } from 'store/preferences/slice';

import usePrivileges from '../hooks/usePrivileges';

import styles from './App.scss';

// in this array, place all the state names you don't want to have sidenavigation or topHeader for
const OMIT_HEADERS = ['login', 'register', 'activation', 'createUser', 'logout'];

// TODO: Let's fix everything
const Lazy = (route: RouteComponent) => {
  const params = useParams();
  return (
    <ErrorBoundary>
      <route.element {...route} params={params}>
        {route.childRoutes && (
          <Switch>
            {route.childRoutes.map((childRoute) => (
              <Route
                key={childRoute.path}
                path={childRoute.path}
                exact={childRoute.exact}
                render={(props) => <Lazy key={childRoute.path} {...props} {...childRoute} />}
              />
            ))}
          </Switch>
        )}
      </route.element>
    </ErrorBoundary>
  );
};

const allRoutes = routeFilterer(createRoutes);

const App: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const authState = useSelector(selectAuthRequiredDomain());
  const privileges = usePrivileges();
  const { formatMessage } = useIntl();
  useSentry(authState);

  const currentLocation = location.pathname;
  // When we have these routes, don't show login
  const specialRoute =
    currentLocation.includes('activation') ||
    currentLocation.includes('erecAuth') ||
    currentLocation.includes('sso') ||
    currentLocation.includes('cardLogout') ||
    currentLocation.includes('logout') ||
    location.hash.includes('erecAuth'); // for legacy signer

  const routes = useMemo(
    () =>
      allRoutes.filter((r) => {
        // TODO: create user should be done inside the app via user management
        if (r.name === Features.CreateUser) {
          return true;
        }
        if (r.name === AlwaysOnFeatures.Logout || r.name === AlwaysOnFeatures.NotFound) {
          return true;
        }
        return authState.loggedIn ? !r.public : r.public;
      }),
    [authState.loggedIn],
  );

  // fallback for old links
  useEffectOnce(() => {
    if (location.hash) {
      // remove hash and navigate to new location
      history.replace(location.hash.replace('#', ''));
    }
  });

  useEffect(() => {
    // if the user is already registered, and it is not one of the special rout, exit.
    if (specialRoute) {
      return;
    }

    // if authentication is not started, check login status
    if (authState.state === State.NOT_STARTED) {
      dispatch(checkLoginStatus());
      return;
    }

    // When authentication is successfully
    if (authState.state === State.SUCCESS) {
      // if the user loggedIn
      if (authState.loggedIn) {
        if (isEmpty(privileges)) {
          dispatch(getPrivileges());
        }

        // if route is not eResepti, fetch preferences
        if (!currentLocation.includes('eResepti')) {
          dispatch(fetchPreferences());
        }
        return;
      }

      // If the cookie expired, redirect to log in or register page
      if (authState.userInfo.loginState.clientRegisteringCookieValid) {
        history.push('/login');
      } else {
        history.push('/register');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.state, currentLocation]); // SSO depends on currentLocation. Workaround until a more complete solution is found

  const resolveLocation = () => OMIT_HEADERS.some((item) => currentLocation.includes(item));

  // TODO: create message
  if (authState.state === State.FAILED) {
    return (
      <SplashScreen>
        <LoginWrapper>
          <div className="text-danger py-5 text-center">{formatMessage(messages.authenticationError)}</div>
        </LoginWrapper>
      </SplashScreen>
    );
  }

  if (authState.state !== State.SUCCESS && !specialRoute) {
    return (
      <SplashScreen>
        <Spinner />
      </SplashScreen>
    );
  }

  return (
    <div className={styles.root}>
      <AppProvider>
        {(openSideNav, isSmallScreen) => (
          <ModalProvider>
            {featureEnabled(Features.SessionTimeout) && authState.loggedIn && <SessionTimeout />}
            <NetworkErrorNotifications location={currentLocation} />
            {!resolveLocation() && sidenavEnabled(window, location.pathname) && !specialRoute && (
              <div className={styles.nav}>
                <Sidenav currentLocation={currentLocation} />
              </div>
            )}
            <div
              className={cn(styles.main, {
                [styles.mainNav]: sidenavEnabled(window, location.pathname) || currentLocation === '/login',
              })}
            >
              {!resolveLocation() && topHeaderEnabled(window, location.pathname) && !specialRoute && (
                <div className={styles.header}>
                  <TopHeader />
                </div>
              )}
              <div
                className={cn(styles.content, {
                  [styles.sideNavMenuOpened]:
                    sidenavEnabled(window, location.pathname) && openSideNav === SIDE_NAV_STATE.OPENED,
                  [styles.smallScreen]: !sidenavEnabled(window, location.pathname) || isSmallScreen,
                })}
              >
                <Switch>
                  {routes.map((route) => (
                    <Route
                      key={route.path}
                      path={route.path}
                      exact={route.exact}
                      render={(props) => <Lazy {...props} {...route} />}
                    />
                  ))}
                </Switch>
              </div>
            </div>
          </ModalProvider>
        )}
      </AppProvider>
    </div>
  );
};

export default App;
