import { useEffect, useState } from 'react';

import { Privileges } from 'types/privileges';

import { handleCatch } from 'utils/errors';
import CacheQueue from 'utils/CacheQueue';

import getCustomerFeatures from 'services/customers/features';

export const cacheQueue = new CacheQueue(async (customerId: number) => (await getCustomerFeatures(customerId)).data);

export default function useCustomerPrivileges(customerId: number): null | Privileges {
  const [privileges, setPrivileges] = useState<Privileges>(cacheQueue.cache[customerId] || null);

  useEffect(() => {
    cacheQueue.call(customerId).then(setPrivileges).catch(handleCatch);
  }, [customerId]);

  return privileges || null;
}
