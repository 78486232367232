/*
 * Login Messages
 *
 * This contains all the text for the Login component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  cookiesDisabled: {
    id: 'app.components.Login.cookiesDisabled',
    defaultMessage: 'Please allow cookies from your browser settings to continue',
  },
  userName: {
    id: 'app.components.Login.username',
    defaultMessage: 'Username',
  },
  password: {
    id: 'app.components.Login.password',
    defaultMessage: 'Password',
  },
  passwordAgain: {
    id: 'app.components.Login.passwordAgain',
    defaultMessage: 'Confirm password',
  },
  login: {
    id: 'app.components.Login.login',
    defaultMessage: 'Login',
  },
  loggingin: {
    id: 'app.components.Login.loggingin',
    defaultMessage: 'Logging in',
  },
  email: {
    id: 'app.components.Login.email',
    defaultMessage: 'Enter your email',
  },
  phoneNumber: {
    id: 'app.components.Login.phoneNumber',
    defaultMessage: 'Enter your phone number',
  },
  firstName: {
    id: 'app.components.Login.firstName',
    defaultMessage: 'Enter your first name',
  },
  lastName: {
    id: 'app.components.Login.lastName',
    defaultMessage: 'Enter your last name',
  },
  accept: {
    id: 'app.components.Login.accept',
    defaultMessage: 'Accept',
  },
  wrongMailFormat: {
    id: 'app.components.Login.wrongMailFormat',
    defaultMessage: 'The password must be in the format asd@asd.fi',
  },
  selectPermissionsLevel: {
    id: 'app.components.Login.selectPermissionsLevel',
    defaultMessage: 'Select permission level',
  },
  operationSuccessful: {
    id: 'app.components.Login.operationSuccessful',
    defaultMessage: 'Operation successful! Check your email to activate your account',
  },
  redirection: {
    id: 'app.components.Login.redirection',
    defaultMessage: 'Redirection',
  },
  shortPhoneNumber: {
    id: 'app.components.Login.shortPhoneNumber',
    defaultMessage: 'The phone number must be 10 characters long',
  },
  newUser: {
    id: 'app.components.Login.newUser',
    defaultMessage: 'Creating new user',
  },
  accActivated: {
    id: 'app.components.Login.accActivated',
    defaultMessage: 'Your Domacare username has been activated and your password has been set',
  },
  goToLogin: {
    id: 'app.components.Login.goToLogin',
    defaultMessage: 'Go to login',
  },
  passwordsDoNotMatch: {
    id: 'app.components.Login.passwordsDoNotMatch',
    defaultMessage: 'Passwords do not match',
  },
  passwordIsTooShort: {
    id: 'app.components.Login.passwordIsTooShort',
    defaultMessage: 'Your password must be at least 6 characters long',
  },
  activationError: {
    id: 'app.components.Login.activationError',
    defaultMessage: 'Activation error',
  },
  smsVerificationFailed: {
    id: 'app.components.Login.smsVerificationFailed',
    defaultMessage: 'SMS authentication failed',
  },
  enterSmsToken: {
    id: 'app.components.Login.enterSmsToken',
    defaultMessage: 'Enter SMS token',
  },
  smsToken: {
    id: 'app.components.Login.smsToken',
    defaultMessage: 'SMS token',
  },
  send: {
    id: 'app.components.Login.send',
    defaultMessage: 'Send',
  },
  tokenAuthFailed: {
    id: 'app.components.Login.tokenAuthFailed',
    defaultMessage: 'Token authentication failed',
  },
  activationFailed: {
    id: 'app.components.Login.activationFailed',
    defaultMessage: 'An error occurred during activation',
  },
  userHasBeenCreated: {
    id: 'app.components.Login.userHasBeenCreated',
    defaultMessage: 'User has been created',
  },
  unableToCreateUser: {
    id: 'app.components.Login.unableToCreateUser',
    defaultMessage: 'Unable to create user',
  },
  sessionConflict: {
    id: 'app.components.Logout.sessionConflict',
    defaultMessage: 'Session conflict',
  },
  sessionInvalidated: {
    id: 'app.components.Logout.sessionInvalidated',
    defaultMessage:
      'Your session has been invalidated due to a conflict with another session from another browser tab.',
  },
  loginAgain: {
    id: 'app.components.Logout.loginAgain',
    defaultMessage: 'Click here to login again',
  },
});
