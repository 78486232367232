export default class CacheQueue<T extends (...args: never[]) => Promise<any>> {
  callback: T;

  cache: Record<number | string, Awaited<ReturnType<T>>> = {};

  queue: Record<number | string, Promise<Awaited<ReturnType<T>>>> = {};

  constructor(callback: T) {
    this.callback = callback;
  }

  // the 'call' method to handle caching and errors more effectively
  async call(...args: Parameters<T>): Promise<Awaited<ReturnType<T>>> {
    const key = this.getKey(args);

    if (this.cache[key]) {
      return this.cache[key];
    }

    if (this.queue[key] instanceof Promise) {
      return this.queue[key];
    }

    const response = this.callback(...args);

    // Wrap the response in a Promise and handle errors
    const resultPromise = response
      .then((result) => {
        this.cache[key] = result; // Cache the result
        delete this.queue[key]; // Remove the promise from the queue once resolved
        return result;
      })
      .catch((error) => {
        delete this.queue[key]; // Remove the promise from the queue if there's an error
        throw error; // Re-throw the error for handling in the caller function
      });

    this.queue[key] = resultPromise;
    return resultPromise;
  }

  // Add a method to clear the cache
  clear(key?: number | string): void {
    if (key) {
      // Clear a specific entry in the cache
      delete this.cache[key];
      delete this.queue[key];
    } else {
      // Clear the entire cache
      this.cache = {};
      this.queue = {};
    }
  }

  // Helper method to determine the cache key
  private getKey(args: Parameters<T>): string | number {
    const firstArg = args[0];

    if (this.isValidKey(firstArg)) {
      return firstArg;
    }
    throw new Error('Invalid cacheKey or the first argument is not a number or string');
  }

  // Helper method to validate cache key
  private isValidKey(key: any): key is string | number {
    return typeof key === 'string' || typeof key === 'number';
  }
}
