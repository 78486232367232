import { createAction, createAsyncThunk, createSlice, SerializedError, nanoid } from '@reduxjs/toolkit';

import { getUserAPI } from 'services/user';

import { CurrentUserEnum, LoginAuth } from 'types/AuthRequired';
import { State } from 'types/State';

import { setClientSessionToken } from 'utils/localStorage';

export const AUTH_REDUCER_NAME = 'auth';

export const checkLoginStatus = createAsyncThunk(`${AUTH_REDUCER_NAME}/checkLoginStatus`, async () => {
  const response = await getUserAPI();

  return response.data;
});

const GENERATE_CLIENT_SESSION_TOKEN = `${AUTH_REDUCER_NAME}/generateClientSessionToken`;
const REMOVE_CLIENT_SESSION_TOKEN = `${AUTH_REDUCER_NAME}/removeClientSessionToken`;

export const generateClientSessionToken = createAction(GENERATE_CLIENT_SESSION_TOKEN);
export const removeClientSessionToken = createAction(REMOVE_CLIENT_SESSION_TOKEN);

export type AuthState = {
  userInfo: LoginAuth;
  state: State;
  error: null | SerializedError;
  loggedIn: boolean;
  previousState: null | string;
  userType: CurrentUserEnum;
  currentDB: null | string;
  clientSessionToken: null | string;
};

const initialState = {
  userInfo: {},
  state: State.NOT_STARTED,
  loggedIn: false,
  previousState: null,
  currentDB: null,
  clientSessionToken: null,
} as AuthState;

const slice = createSlice({
  name: AUTH_REDUCER_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(checkLoginStatus.pending, (draft) => {
      draft.state = State.PENDING;
      draft.error = null;
    });
    builder.addCase(checkLoginStatus.fulfilled, (draft, action) => {
      draft.state = State.SUCCESS;
      draft.error = null;
      draft.userInfo = action.payload;
      draft.loggedIn = action.payload.loginState.loggedIn;
      draft.userType = action.payload.loginState.userType as CurrentUserEnum;
      draft.currentDB = action.payload.currentDb;
      // if page is refreshed, but there is no clientSessionToken in the state, but there is one in the localStorage, it means that the session is not stale
      if (draft.clientSessionToken === null && localStorage.getItem('clientSessionToken')) {
        draft.clientSessionToken = localStorage.getItem('clientSessionToken');
      }
    });
    builder.addCase(checkLoginStatus.rejected, (draft, action) => {
      draft.state = State.FAILED;
      draft.loggedIn = false;
      draft.error = action.error;
      console.log('cannot get login state');
      console.log(action.error);
    });
    builder.addCase(generateClientSessionToken, (draft) => {
      draft.clientSessionToken = nanoid();
      setClientSessionToken(draft.clientSessionToken);
    });
    builder.addCase(removeClientSessionToken, (draft) => {
      draft.clientSessionToken = null;
      removeClientSessionToken();
    });
  },
});

// export const {} = slice.actions;

export default slice.reducer;
