import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import Spinner from 'common/Spinner';

import { logout } from 'services/authentication/logout';

import useEffectOnce from 'hooks/useEffectOnce';
import { cacheQueue } from 'hooks/useCustomerPrivileges';

import history from 'utils/history';
import { handleCatch } from 'utils/errors';

import { RESET_STORE } from 'store/constants';
import SplashScreen from 'shared/SplashScreen';
import { removeClientSessionToken } from 'store/auth/slice';
import messages from 'Login/messages';

export enum LogoutReason {
  SESSION_CONFLICT = 'SESSION_CONFLICT',
}

export const Logout = () => {
  const dispatch = useDispatch();
  const { reason } = useParams<{ reason?: string }>();

  const { formatMessage } = useIntl();

  useEffectOnce(() => {
    // when logging out the cacheQueue should be cleared
    cacheQueue.clear();

    logout()
      .catch(handleCatch)
      .finally(() => {
        dispatch({ type: RESET_STORE });
        dispatch(removeClientSessionToken());
        if (!reason) {
          history.push('/login');
        }
      });
  });

  return (
    <SplashScreen>
      {!reason && <Spinner />}
      {reason === LogoutReason.SESSION_CONFLICT && (
        <div className="bg-white p-3 text-center">
          <h1 className="text-danger">{formatMessage(messages.sessionConflict)}</h1>
          <p>{formatMessage(messages.sessionInvalidated)}</p>
          <p>
            <Link to="/login">{formatMessage(messages.loginAgain)}</Link>
          </p>
        </div>
      )}
    </SplashScreen>
  );
};

export default Logout;
