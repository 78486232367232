/* eslint-disable import/no-import-module-exports */
import { configureStore as createStore } from '@reduxjs/toolkit';

import { CurrentUserEnum } from 'types/AuthRequired';

import api from 'utils/api';
import history from 'utils/history';
import { isClientSessionTokenStale } from 'utils/localStorage';

import createReducer from 'store/reducers';
import { RESET_STORE } from 'store/constants';
import { LogoutReason } from 'Login/containers/Logout/Logout';

export type { RootState } from 'store/RootState';

const initialState = {};

// Create the store with two middlewares

const store = createStore({
  reducer: createReducer(),
  middleware: (getDefaultMiddleware) => [
    // https://redux-toolkit.js.org/api/getDefaultMiddleware#included-default-middleware
    ...getDefaultMiddleware({ serializableCheck: true }),
  ],
  preloadedState: initialState,
  devTools: process.env.NODE_ENV !== 'production',
});

export type Store = {
  asyncReducers: Record<string, unknown>;
} & typeof store;

(store as Store).asyncReducers = {}; // Async reducer registry

api.interceptors.request.use(
  (config) => {
    // eslint-disable-next-line no-param-reassign
    const state = store.getState();
    // this means that user has logged in from different tab on a different account and current session is stale
    if (isClientSessionTokenStale(state.auth.clientSessionToken)) {
      history.push(`/logout/${LogoutReason.SESSION_CONFLICT}`);
      // we turn the request into a logout request, to make sure no accidental POST or PATCH calls can be made
      // We cannot throw an error here, because it would fail the logout request, leaving the user logged in
      // eslint-disable-next-line no-param-reassign
      config.url = '/logout'; // cancel the request
      // eslint-disable-next-line no-param-reassign
      config.method = 'post';
    }
    return config;
  },
  (error) => Promise.reject(error),
);

// clear the store when user got logged out
api.interceptors.response.use(
  (response) => response,
  (error) => {
    const config = error?.response?.config;
    // if 403 we check if the user is logged in with smartcard from eResepti or social-archive
    if (error.response && error.response.status === 403) {
      const { userInfo } = store.getState().auth;
      if (userInfo.currentUserType === CurrentUserEnum.DOMACARE_WITH_SMARTCARD) {
        history.push('/cardLogout/noCard');
        store.dispatch({ type: RESET_STORE });
      }
    }
    if (error.response && error.response.status === 401) {
      // regular failure, logout and clean up store
      if (!config?.url.includes('/register')) {
        history.push('/login');
      }
      store.dispatch({ type: RESET_STORE });
    }
    return Promise.reject(error);
  },
);

// Make reducers hot reloadable, see https://redux.js.org/usage/configuring-your-store#hot-reloading
/* istanbul ignore next */
if (module.hot) {
  module.hot.accept('./reducers', () => {
    import('store/reducers').then((reducerModule) => {
      const createReducers = reducerModule.default;
      const nextReducers = createReducers((store as Store).asyncReducers);

      store.replaceReducer(nextReducers);
    });
  });
}

// Export custom dispatch type
export type AppDispatch = typeof store.dispatch;

export default store as Store;
